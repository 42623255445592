import { canvas, previewCanvas, typeEl } from './define'
import { useScale } from './helper/useScale'
import { store } from './store'
import postcard from './data/postcard.data.json'
import lesserCalorie from './data/lesserCalorie.data.json'
import polaroid3 from './data/polaroid3.data.json'
import polaroid4 from './data/polaroid4.data.json'
import polaroid5 from './data/polaroid5.data.json'
import polaroid7 from './data/polaroid7.data.json'
import photograph5 from './data/photograph5.data.json'
import photograph5_1 from './data/photograph5-1.data.json'
import photograph6 from './data/photograph6.data.json'
import photograph5a from './data/photograph-a5.data.json'

const { searchParams } = new URL(window.location.href)

const typeList = [
  { name: '明信片', param: postcard },
  { name: '小卡', param: lesserCalorie },
  { name: '3寸拍立得', param: polaroid3 },
  { name: '4寸拍立得', param: polaroid4 },
  { name: '5寸拍立得', param: polaroid5 },
  { name: '7寸拍立得', param: polaroid7 },
  { name: '5寸照片', param: photograph5 },
  { name: '5寸方形照片', param: photograph5_1 },
  { name: '6寸照片', param: photograph6 },
  { name: 'A5大尺寸照片', param: photograph5a },
]
const type = parseInt(searchParams.get('type') ?? '0')
const findType = typeList[type] ?? typeList[0]!

// 设置预览的画布宽高
previewCanvas.width = findType.param.width / 10
previewCanvas.height = findType.param.height / 10

// 获取绘制参数
const drawParam = useScale(findType.param)

// 设置排版画布的宽高
canvas.width = drawParam.width
canvas.height = drawParam.height

// 设置绘制参数
store.drawParam = drawParam

// 设置类型切换
typeList.forEach((item, index) => {
  const link = document.createElement('a')
  link.className = `px-4 py-2 mb-4 mr-4 rounded-md border border-gray-300 hover:bg-gray-50 active:bg-gray-100 transition-colors duration-200 cursor-pointer text-center`
  if (index === type) {
    link.className += ' selected-type'
  } else {
    link.href = `/?type=${index}`
  }

  link.innerText = item.name
  typeEl.appendChild(link)
})

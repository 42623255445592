{
  "width": 1140,
  "height": 780,
  "drawArea": {
    "startX": 10,
    "startY": 38,
    "endX": 770,
    "endY": 1102
  },
  "layout": {
    "row": 8,
    "column": 8
  },
  "offset": {
    "x": 3,
    "y": 3
  },
  "scale": 11.814159292035399,
  "R": 6,
  "fontSize": 12,
  "direction": "col",
  "assistDirection": "col",
  "withBack": false
}

import {
  canvas,
  clearBtn,
  ctx,
  downloadBtn,
  hookMake,
  uploader,
} from './define'
import { addDpiToPng } from './helper/addDpiToPng'
import { addUploadInfo } from './helper/addUploadInfo'
import { appendPositionPoint } from './helper/appendPositionPoint'
import { buildContainer } from './helper/buildContainer'
import { catContainerDetail } from './helper/catContainerDetail'
import { date } from './helper/date'
import { drawContainer } from './helper/drawContainer'
import { drawCornerText } from './helper/drawCornerText'
import { drawSubline } from './helper/drawSubline'
import { flatFileNode } from './helper/flatFileNode'
import { store } from './store'

// 清空按钮点击事件
clearBtn.addEventListener('click', function () {
  if (store.downloadIng) {
    return addUploadInfo('有排版正在处理', 'warning')
  }

  window.location.href += ''
})

// 下载按钮点击事件
downloadBtn.addEventListener('click', async () => {
  if (store.downloadIng) return void 0
  store.downloadIng = true
  downloadBtn.textContent = '排版制作中'

  try {
    // 实现下载功能的代码
    const folderList = uploader.folderStructure
    if (folderList.length === 0) {
      throw new Error('文件夹为空')
    }

    // 排版文件
    const containers = buildContainer(
      flatFileNode(folderList),
      store.drawParam.layout
    )

    // 保存操作文本记录
    const cat = catContainerDetail(containers, true)
    store.zip.put(`${store.zip.filename}.csv`, cat)

    /** 开始绘制 */
    await drawContainer(ctx, store.drawParam, containers, {
      // 每次循环绘制开始前
      onLoopStart(index) {
        hookMake.loopStart.notify(index)
      },

      // 绘制开始前
      onDrawStart(side) {
        hookMake.drawStart.notify(side)

        // 如果绘制方向发生颠倒
        if (store.drawParam.direction === 'col') {
          // 如果是正面
          if (side === 'front') {
            // 如果是正面
            ctx.rotate(Math.PI * 1.5)
            // rotate 是叠加态的
            ctx.translate(-canvas.height, 0)
          } else {
            // 反面
            ctx.rotate(Math.PI * 0.5)
            ctx.translate(0, -canvas.width)
          }
        }
      },

      // 绘制中...
      onDrawing(fileName, side) {
        hookMake.drawIng.notify(fileName, side)

        // TODO 已排版完成，绘制栅格, 绘制定点信息
        // 栅格
        drawSubline(ctx, store.drawParam, 2)
        // 绘制定点信息
        appendPositionPoint(ctx, store.drawParam)

        // TODO 写入备注信息以及编号
        const input = ` [${date('m/d h:i:s')}] ${store.data.name}: ${store.data.note}`
        // 备注
        drawCornerText(ctx, 'topLeft', input, store.drawParam)
        // 编号
        drawCornerText(
          ctx,
          'bottomRight',
          `序号: ${store.getCurrent()}${fileName.includes('反面') ? '+' : ''}`,
          store.drawParam,
          {
            color: 'red',
          }
        )
      },

      // 绘制结束开始保存
      async onDrawOver(fileName, side) {
        // 保存图片信息，这一步特别久！
        const blob = await new Promise<Blob | null>((res) => canvas.toBlob(res))
        hookMake.drawOver.notify(fileName)

        // 保存到zip文件夹
        const dpiBlob = await addDpiToPng(blob!, 300)
        store.zip.put(fileName, dpiBlob)
        hookMake.putFile.notify(fileName)

        // 如果当前压缩包过大时，先进行保存
        if (store.zip.isBlobSizeOver()) {
          hookMake.savePart.notify()
          await store.zip.save()
        }
      },

      // 每回循环结束
      onLoopOver(index) {
        hookMake.loopOver.notify(index)
      },

      // 发生异常时
      onError(err: any) {
        hookMake.fail.notify(err)
      },
    })

    // 下载
    if (store.zip.currentSize !== 0) {
      await store.zip.save()
      hookMake.over.notify()
    }
  } catch (err: any) {
    hookMake.fail.notify(err)
  }

  store.downloadIng = false
  downloadBtn.textContent = '制作排版'
})
